import PropTypes from 'prop-types'
import queryString from 'query-string'
import UAParser from 'ua-parser-js';

function getQString () {
  const allowedParamNames = ['iframe', 'beneficiary_id', 'beneficiary_cause_id', 'beneficiary_type']
  const params = queryString.parse(location.search)
  let paramsOut = {}
  allowedParamNames.map(paramName => {
    if (params.hasOwnProperty(paramName)) paramsOut[paramName] = params[paramName]
  })
  return queryString.stringify(paramsOut)
}

export function normalizeParsedQS (parsedQS) {
    if (parsedQS.page) {
      parsedQS.page = parseInt(parsedQS.page)
    }

    if (parsedQS.type && typeof parsedQS.type === 'string') {
      parsedQS.type = [parsedQS.type]
    }
    if (parsedQS.category) {
      if (typeof parsedQS.category === 'string') {
        parsedQS.category = [parsedQS.category]
      }
      parsedQS.category = parsedQS.category.map((val, idx) => {
        return parseInt(val)
      })
    }
    if (parsedQS.fields_of_action) {
      if (typeof parsedQS.fields_of_action === 'string') {
        parsedQS.fields_of_action = [parsedQS.fields_of_action]
      }
      parsedQS.fields_of_action = parsedQS.fields_of_action.map((val, idx) => {
        return parseInt(val)
      })
    }
    if (parsedQS.organization_types) {
      if (typeof parsedQS.organization_types === 'string') {
        parsedQS.organization_types = [parsedQS.organization_types]
      }
      parsedQS.organization_types = parsedQS.organization_types.map((val, idx) => {
        return parseInt(val)
      })
    }
    if (parsedQS.geographical_scopes) {
      if (typeof parsedQS.geographical_scopes === 'string') {
        parsedQS.geographical_scopes = [parsedQS.geographical_scopes]
      }
      parsedQS.geographical_scopes = parsedQS.geographical_scopes.map((val, idx) => {
        return parseInt(val)
      })
    }
}


export const STORAGE_STATE_KEY = 'fm_app_state'
export const DEFAULT_STATE = {
  fmextInfo: {
    isInstalled: false,
    isFetching: false
    //version: 0
  },
  fmext1zlInfo: {
    isFetching: false,
    isGranted: false,
    beneficiary: null,
    isEmailConfirmed: false
  },
  userInfo: {
    isFetching: false,
    isAnonymous: true,
    user: null,
    beneficiary: null,
    beneficiaryCause: null
  },
  partnerSearch: {
    categories: FM.partnerCategories || [], // a list of categories defined in document body JS
    categoryId: null,
    keywords: '',
    results: {},
    // --- flags, settings ---
    qstring: getQString(),
    minLength: 3,
    pristine: true, // was any search made yet?
    inProgress: false // general search flag
  },
  beneficiarySearch: {
    fields_of_action_choices: FM.fieldsOfActionChoices || [], // a list defined in document body JS
    organization_types_choices: FM.organizationTypesChoices || [], // a list defined in document body JS
    geographical_scopes_choices: FM.geographicalScopesChoices || [], // a list defined in document body JS
    keywords: '',
    fields_of_action: null,
    organization_types: null,
    geographical_scopes: null,
    results: {},
    // --- flags, settings ---
    qstring: getQString(),
    minLength: 3,
    pristine: true, // was any search made yet?
    inProgress: false // general search flag
  },
  discountSearch: {
    types: null,
    categories: null,
    page: null,
    results: {},
    // --- flags, settings ---
    qstring: getQString(),
    pristine: true, // was any search made yet?
    inProgress: false // general search flag
  }
}

export const userInfoPropShape = {
  isFetching: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    id: PropTypes.number
  }),
  beneficiary: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    thumbnail: PropTypes.string.isRequired
  })
}
export const userInfoPropType = PropTypes.shape(userInfoPropShape)


export const beneficiarySearchPropShape = {
  keywords: PropTypes.string,
  results: PropTypes.object,
  qstring: PropTypes.string,
  minLength: PropTypes.number,
  pristine: PropTypes.bool,
  isFetching: PropTypes.bool
}
export const beneficiarySearchPropType = PropTypes.shape(beneficiarySearchPropShape)


export const partnerSearchPropShape = {
  categoryId: PropTypes.number,
  keywords: PropTypes.string,
  results: PropTypes.object,
  qstring: PropTypes.string,
  minLength: PropTypes.number,
  pristine: PropTypes.bool,
  isFetching: PropTypes.bool
}
export const partnerSearchPropType = PropTypes.shape(partnerSearchPropShape)


export const discountSearchPropShape = {
  types: PropTypes.array,
  categories: PropTypes.array,
  page: PropTypes.number,
  results: PropTypes.object,
  qstring: PropTypes.string,
  pristine: PropTypes.bool,
  isFetching: PropTypes.bool
}
export const discountSearchPropType = PropTypes.shape(discountSearchPropShape)


export const fmextInfoPropShape = {
  isFetching: PropTypes.bool.isRequired,
  isInstalled: PropTypes.bool.isRequired
}
export const fmextInfoPropType = PropTypes.shape(fmextInfoPropShape)


/*
`saveStateToStorage()` and `getStateFromStorage()` aren't Redux' reducers
because loading state from store happens only on app load
and saving is periodical and doesn't update the state.
*/

export function saveStateToStorage(state) {
  let stateInfo = {
    //state: state,
    state: {userInfo: state.userInfo},  // we need to save only userInfo key
    timestamp: (new Date()).toJSON()
  }
  stateInfo = JSON.stringify(stateInfo)
  localStorage.setItem(STORAGE_STATE_KEY, stateInfo)
}

export function getStateFromStorage() {
  const maxAge = 1000*60*5  // 5 minutes
  let stateInfo = localStorage.getItem(STORAGE_STATE_KEY)

  if (!stateInfo) {
    return null
  }

  stateInfo = JSON.parse(stateInfo)
  const age = new Date() - new Date(stateInfo.timestamp)
  if (age < maxAge) {
    return stateInfo['state']
  }

  return null
}

export function getInitialState() {
  // returns DEFAULT_STATE with `userInfo` key taken from local storage if it was found there
  const savedState = getStateFromStorage()
  return savedState ? Object.assign({}, DEFAULT_STATE, savedState) : DEFAULT_STATE
}

export function detectBrowser() {
    // Opera 12.0+
    const isOpera = navigator.userAgent.indexOf(' OPR/') >= 0;
    // Firefox 1.0+
    const isFirefox = typeof InstallTrigger !== 'undefined';
    // Safari: 
    const isSafari = (navigator.userAgent.toLowerCase().indexOf('safari') != -1 && navigator.userAgent.toLowerCase().indexOf('chrome') == -1);
    // Internet Explorer 6-11
    const isIE = /*@cc_on!@*/false || !!document.documentMode;
    // Edge Chromium
    const isEdge = navigator.userAgent.indexOf(' Edg/') >= 0;
    // Chrome 1+
    const isChrome = !!window.chrome;
    // Blink engine detection
    const isBlink = (isChrome || isOpera) && !!window.CSS;

    //if (isBlink) return 'blink'  // ignore as it detects actual Chrome as Blink!
    if (isIE) return 'ie'
    if (isOpera) return 'opera'
    if (isEdge) return 'edge'
    if (isChrome) return 'chrome'
    if (isSafari) return 'safari'
    if (isFirefox) return 'firefox'
    
    return ''
}

export function browserDetection(addClasses=false){
  let currentBrowser=new UAParser(navigator.userAgent);
  
  let browserName = '-'
  let browserVersion = 0;
  let osName = '-'
  let osVersion = 0;
  let deviceType = '-';
  let screenType='no-touch';

  if(typeof currentBrowser.getBrowser().name !='undefined'){
    browserName = currentBrowser.getBrowser().name.toLowerCase().replace(/\s/g,'-');
    browserVersion = parseInt(currentBrowser.getBrowser().version);
    osName = currentBrowser.getOS().name.toLowerCase().replace(/\s/g,'-');
    osVersion = currentBrowser.getOS.version;
    deviceType=currentBrowser.getDevice().type;

    if(!deviceType){
      if(('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0)){
          deviceType='tablet';
      } else {
          deviceType='desktop';
      }
    } else {
      //deviceType='desktop';
    }
    deviceType=deviceType.toLowerCase();
  
    if(('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0)){
      screenType='touch';
    }
  
    if(screenType=='touch' && osName=='mac-os' && browserName=='safari'){
      osName='ipados';
    }
  }

  let browserClasses=[
    'browser-'+browserName,
    'fanimani-browser-'+browserName,
    'browser-'+browserName+(browserVersion ? '-'+browserVersion : ''),
    'fanimani-browser-'+browserName+(browserVersion ? '-'+browserVersion : ''),
    'browser-type-'+deviceType,
    'fanimani-browser-type-'+deviceType,
    'browser-screen-'+screenType,
    'fanimani-browser-screen-'+screenType,
    'browser-os-'+(osName ? osName : 'unknown'),
    'fanimani-browser-os-'+(osName ? osName : 'unknown')
  ];

  if((browserName=='safari' && browserVersion>13) || (browserName=='mobile-safari' && browserVersion>14)){
    browserClasses.push('fanimani-browser-'+browserName+'-modern','browser-'+browserName+'-modern')
  }

  document.body.classList.add(...browserClasses);

  return {
    'browserName': browserName,
    'browserVersion': browserVersion,
    'osName': osName,
    'osVersion': osVersion,
    'deviceType': deviceType,
    'deviceScreenType': screenType,
    'classes': browserClasses,
    'userAgent': navigator.userAgent
  }
}