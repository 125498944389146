import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { userInfoPropType, beneficiarySearchPropType } from '../utils'
//TODO: Change slider to glidejs
//import Slider from 'react-slick'
import BeneficiarySearchBar from '../beneficiary-search/containers/BeneficiarySearchBar'
import BeneficiarySearchResults from '../beneficiary-search/containers/BeneficiarySearchResults'
import BeneficiarySearchItem from '../beneficiary-search/containers/BeneficiarySearchItem'
//import { defaultSliderSettingsNarrow } from '../utils'

class MobileAppBeneficiarySearch extends Component {
  componentDidMount() {
    if(this.props.popularOrgs){

      // TODO: move breakpoints to a common setting
      new Glide('#glide-beneficiary-suggestions-popular',{
        keyboard: false,
        perView: 5,
        breakpoints: {
          1024: {
            perView: 4
          },
          992: {
            perView: 3
          },
          640: {
            perView: 2
          },
        }

      }).mount();
    }
  }
  render () {
    const { dispatch, userInfo, parentId, popularOrgs } = this.props
    
    return (
        <div>
          <div className="mobileapp-beneficiary-search">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <BeneficiarySearchBar updateQueryString={true} />
                </div>
              </div>
            </div>
            <BeneficiarySearchResults updateQueryString={true} 
                                      itemType="autoselect-link-self" />
          </div>
          <div className="container">
              <div className="row">
                <div className="col-md-12 text-center">
                  <p>&nbsp;</p>
                  <p>lub wybierz jedną z popularnych organizacji:</p>
                </div>
              </div>
            </div>
            {popularOrgs ? (
              <div>
                <div id="beneficiary-suggestions-popular" className="beneficiary-suggestions">
                  <div className="glide glide-beneficiary-suggestions" id="glide-beneficiary-suggestions-popular">
                    <div className="glide__track glide__track--modal" data-glide-el="track">
                      <ul className="glide__slides">
                        {popularOrgs.map((beneficiary, idx) => (
                        <li className="glide__slide" key={idx}>
                          <div>
                            <BeneficiarySearchItem beneficiary={beneficiary}
                                                
                                                itemType="inline"
                                                className="result result-inline organization" />
                          </div>
                        </li>
                      ))}
                      </ul>
                    </div>
                    <div className="glide__arrows glide__arrows--modal" data-glide-el="controls">
                      <span className="glide__arrow glide__arrow--left" data-glide-dir="|<"><i className="fa fa-chevron-left"></i></span>
                      <span className="glide__arrow glide__arrow--right" data-glide-dir="|>"><i className="fa fa-chevron-right"></i></span>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
        </div>
    )
  }
}



MobileAppBeneficiarySearch.propTypes = {
  userInfo: userInfoPropType,
  popularOrgs: PropTypes.array,
}


// Which props do we want to inject, given the global state?
function mapStateToProps(state) {
  return {
    userInfo: state.userInfo
  }
}

// Wrap the component to inject dispatch and state into it
MobileAppBeneficiarySearch = connect(mapStateToProps)(MobileAppBeneficiarySearch)

export { MobileAppBeneficiarySearch as default }
