import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { userInfoPropType, beneficiarySearchPropType } from '../../utils'
import { fetchBeneficiarySearch } from '../actions'


class BeneficiarySearchBar extends Component {
  constructor(props) {
    super(props)
    this.state = {keywordsValue: props.beneficiarySearch.keywords, formValid: false}
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentWillReceiveProps (nextProps) {
    if (this.props.beneficiarySearch.keywords != nextProps.beneficiarySearch.keywords) {
      this.setState({keywordsValue: nextProps.beneficiarySearch.keywords});
    }
  }

  render () {
    // `dispatch` is injected by connect() call:
    const { dispatch, userInfo, beneficiarySearch, updateQueryString, searchType } = this.props
    const btnClass = classNames('btn btn-red', {'is-loading': beneficiarySearch.isFetching})

    return (
        <div className="search-bar-flat beneficiary-search-wrap">
            <form role="search" name="search_form" method="get" onSubmit={this.handleSubmit}
                  className="beneficiary-search-bar">
                <div className="input-group has-validation">
                    <input className="form-control keywords"
                           id="input_keywords"
                           name="keywords"
                           type="text"
                           placeholder="Wpisz nazwę lub miejscowość"
                           required
                           disabled={beneficiarySearch.isFetching}
                           minLength={beneficiarySearch.minLength}
                           onChange={this.handleChange}
                           value={this.state.keywordsValue || ''}/>

                    <button className={btnClass}
                            type="submit"
                            disabled={!this.state.formValid || beneficiarySearch.isFetching}>
                        <span class="fa fa-search"></span>
                    </button>
                    <div class="invalid-feedback">Proszę wpisać co najmniej {beneficiarySearch.minLength} znaki.</div>
                    
                </div>
            </form>
        </div>
    )
  }

  handleChange (e) {
    const val = e.target.value

    this.setState({
      keywordsValue: val,
      formValid: val.trim().length >= this.props.beneficiarySearch.minLength
    });
  }

  handleSubmit (e) {
    e.preventDefault();
    console.log('handleSubmit()')
    console.log(this.props.searchType)
    this.props.dispatch(fetchBeneficiarySearch(
        this.state.keywordsValue, null, null, null, null, this.props.updateQueryString,
        this.props.parentId, this.props.searchType))
    // Report search event
    ga('send', 'event', 'Wyszukiwanie beneficjentów', 'Wyszukiwanie wg słów kluczowych', this.state.keywordsValue.toLowerCase());
  }
}


BeneficiarySearchBar.propTypes = {
  userInfo: userInfoPropType,
  beneficiarySearch: beneficiarySearchPropType,
  updateQueryString: PropTypes.bool,
  parentId: PropTypes.number  // can restrict search to given parent org
}


// Which props do we want to inject, given the global state?
function mapStateToProps(state) {
  return {
    userInfo: state.userInfo,
    beneficiarySearch: state.beneficiarySearch
  }
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps)(BeneficiarySearchBar)
