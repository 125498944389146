import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import SimplePagination from '../../components/SimplePagination'
import { discountSearchPropType, userInfoPropType } from '../../utils'


class DiscountItem extends Component {
  selectBeneficiary(){
    let selectBeneficiaryLink = document.getElementById('discounts-beneficiary-box-select');
    if(selectBeneficiaryLink){
      selectBeneficiaryLink.click();
    }
  }

  render () {
    const { qstring, userInfo, discount: d } = this.props
    //let url = partner.detail_url + (qstring ? ('?' + qstring) : '')

    const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' }
    let validityLabel = ''
    if (d.valid_from) {validityLabel += 'od ' + (new Date(d.valid_from)).toLocaleDateString('pl-PL', dateOptions) }
    if (d.valid_until) {validityLabel += ' do ' + (new Date(d.valid_until)).toLocaleDateString('pl-PL', dateOptions) }

    return (
        <article className="row discount">
            <div className="col-sm-4 col-md-2 shop-logo">
                <a href={d.partner_url}>
                    <img src={d.partner_logo} alt={d.partner_name} />
                </a>
            </div>
          
            <div className="col-sm-8 col-md-10">
                <div className="row">
                    <div className="col-sm-12 col-md-7 discount-info">
                        <h3 className="discount-text">{d.discount_text}</h3>
                        {validityLabel ? <p className="discount-validity">{validityLabel}</p> : null}
                    </div>
                    <div className="col-sm-12 col-md-5 discount-btn-container">
                        { userInfo && userInfo.beneficiary && userInfo.beneficiary.id && userInfo.beneficiary.id!=10 ? (
                            <button className="btn btn-red btn-sm-sq-rounded" data-bs-toggle="modal" data-bs-target={"#discount-"+d.id+"-modal"}>
                                Pobierz kod rabatowy&nbsp;<span className="fa fa-chevron-right" />
                            </button>
                        ) : (
                            <span className="btn btn-red btn-sm-sq-rounded" onClick={this.selectBeneficiary} data-href={"/wspieram/?next="+location.pathname+location.search}>
                                Wybierz organizację, aby pobrać kod&nbsp;<span className="fa fa-chevron-right" />
                            </span>
                        ) }

                    </div>
                </div>
        
                <div className="modal fade" id={"discount-"+d.id+"-modal"}>
                    <div className="modal-dialog modal-dialog-wide discount-modal">
                        <div className="modal-content">
                            <div className="modal-header">
                            	<h5 class="modal-title">{d.partner_name}</h5>
                				<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body ">
                            	<div className="container-fluid text-start">
                            		<div className="row  mb-3">
	                                    <div className="col-sm-3 col-md-3 logo offer-partner-logo">
	                                      <div>
	                                        <img src={d.partner_logo} alt={d.partner_name} />
	                                      </div>
	                                    </div>
	                                    <div className="col-sm-9 col-md-9">
	                                        <h3 className="discount-name">{d.discount_text}</h3>
	                                        <p className="discount-description">{d.discount_description}</p>
	                                    </div>
	                                </div>
                            
		                            {
		                                (d.code == 'Kod rabatowy nie jest wymagany' || d.code == 'kod nie jest wymagany') ? (
		                              		<div className="row">
		                                		<div className="col-md-12 text-center">
		                                  			<span>Kod rabatowy nie jest wymagany</span><br/><br/>
		                                   			<a href={"/go-to-shop/extension/?partner="+d.partner+"&utm_source=webshopadomany&utm_medium=website&utm_campaign=discount_modal"} className="btn btn-red" rel="nofollow">
		                                      			AKTYWUJ DAROWIZNĘ
		                                  			</a>
		                                		</div>
		                              		</div>
		                                ) : (
		                                	<div className="row">
		                                    	<div className="col-md-5 code-col text-center">
		                                    		<h3 className="discount-name">
		                                      			Zaznacz i&nbsp;skopiuj kod&nbsp;rabatowy
		                                    		</h3>
		                                    		<input type="text" readonly="true" value={d.code} />
		                                  		</div>
			                                    <div className="col-sm-2 divider-col d-none d-sm-none d-md-block">
			                                    	<img src={window.FM.STATIC_URL+"img/strzalka_03_360.png"} alt="strzałka" />
			                                    </div>
		                                  		<div className="col-md-5 button-col text-center">
		                                      		<a href={"/go-to-shop/extension/?partner="+d.partner+"&utm_source=webshopadomany&utm_medium=website&utm_campaign=discount_modal"} className="btn btn-red" rel="nofollow">
		                                          		AKTYWUJ DAROWIZNĘ
		                                      		</a>
		                                  		</div>
		                                	</div> 
		                                )
		                            }
		                        </div>
		                   	</div>
                        </div>
                    </div>
                </div>
            </div>
        </article>
    )
  }
}

DiscountItem.propTypes = {
  userInfo: userInfoPropType,
  discount: PropTypes.object.isRequired,
  qstring: PropTypes.string
}


class DiscountSearchResults extends Component {

  render () {
    const { loadPage, userInfo } = this.props
    const { types, categories, results, qstring, isFetching } = this.props.discountSearch
    let resultsContent = null;

    if (isFetching || userInfo.isFetching) {
      resultsContent = (
          <div className="text-center big-top-margin">
              <span className="fa fa-2x fa-refresh fa-spin" />
          </div>
      )
    }
    else if (!results.total) {
      resultsContent = (
          <div className="col-xs-12 text-center big-top-margin">
              <h2 className="title-new">
                <i className="fa fa-frown-o" /><br />
                Hmm... szukaliśmy ale nie znaleźliśmy.<br />
                Poszukajmy jeszcze raz?
              </h2>
          </div>
      )
    } else {
      resultsContent = results.items.map((discount, idx) => (
          <DiscountItem discount={discount} qstring={qstring} userInfo={userInfo} key={idx} />
      ))
    }

    return (
      <div className="results">
          {resultsContent}
          {!isFetching ? (
            <div className="col-xs-12 text-center">
              <SimplePagination pagesCount={results.maxPages || 0}
                                currentPage={results.page || 1}
                                loadPageCallback={loadPage} />
            </div>
          ) : (null)}
      </div>
    )
  }
}

DiscountSearchResults.propTypes = {
  userInfo: userInfoPropType,
  discountSearch: discountSearchPropType,
  loadPage: PropTypes.func
}

export { DiscountItem, DiscountSearchResults }
export default DiscountSearchResults
