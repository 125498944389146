import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import configureStore from './configureStore'
import queryString from 'query-string'
import Cookies from 'js-cookie'
import 'babel-polyfill'

import { DEFAULT_STATE, saveStateToStorage, getInitialState, detectBrowser, browserDetection } from './utils'
import { fetchFMExtInfo, fetch1zlInfo, setBeneficiary } from './actions'
import Fmext1zlCtaButton from './containers/Fmext1zlCtaButton'
import FmextCtaWidget from './containers/FmextCtaWidget'
import FmextGiveAway1zl from './containers/FmextGiveAway1zl'

import PartnerSearchFull from './partner-search/containers/PartnerSearchFull'
import { loadPartnerInitialSearchResults, getPartnerQueryStringChangeHandler } from './partner-search/utils'

import BeneficiarySearchBar from './beneficiary-search/containers/BeneficiarySearchBar'
import BeneficiarySearchFilter from './beneficiary-search/containers/BeneficiarySearchFilters'
import BeneficiarySearchResults from './beneficiary-search/containers/BeneficiarySearchResults'
import BeneficiaryInlineInput from './beneficiary-search/containers/BeneficiaryInlineInput'
import { loadBeneficiaryInitialSearchResults, getBeneficiaryQueryStringChangeHandler } from './beneficiary-search/utils'

import PartnerPage, { DiscountButton } from './containers/PartnerPage'

import DiscountsBeneficiary from './containers/DiscountsBeneficiary'
import MobileAppBeneficiarySearch from './containers/MobileAppBeneficiarySearch'

import DiscountSearchFull from './discounts/containers/DiscountSearchFull'
import { loadInitialDiscounts, getDiscountQueryStringChangeHandler } from './discounts/utils'

/*
  ====
  1. Setup ground stuff
  ====
*/

// some random js codes rely on detectBrowser being globally available
window.detectBrowser = detectBrowser
browserDetection();
window.browserDetection = browserDetection();

const store = configureStore(getInitialState());

// save app state to localStorage periodically
setInterval(function (store) {
  return function () {
    return saveStateToStorage(store.getState())
  }
}(store), 1000*5)


store.dispatch(fetch1zlInfo())  // przypominajka widget needs 1zl info
store.dispatch(fetchFMExtInfo())
setTimeout(() => store.dispatch(fetchFMExtInfo()), 2000)

document.addEventListener('userInfo', function(data){
    store.dispatch(data.detail);
});

const { beneficiary_id } = queryString.parse(location.search)
const pathsHandlingBeneficiaryId = [
    // Insert paths that should be able to set beneficiary from query param here
    '/okazje/',
]

if (beneficiary_id && pathsHandlingBeneficiaryId.indexOf(location.pathname) > -1) {
  store.dispatch(setBeneficiary({id: beneficiary_id}))
}

if (!Cookies.get('utm_source')){
  let { utm_source, utm_medium, utm_campaign } = queryString.parse(location.search)
  if(utm_source){
    Cookies.set('utm_source', utm_source);  
  }
  if(utm_medium){
    Cookies.set('utm_medium', utm_medium);  
  }

  if(utm_campaign){
    Cookies.set('utm_campaign', utm_campaign);  
  }
}


if (location.pathname === '/okazje/') {
  const discountSearchContainer = document.getElementById('discount-search-full')
  if (discountSearchContainer) {
    ReactDOM.render (
      <Provider store={store}>
        <DiscountSearchFull updateQueryString={true}/>
      </Provider>,
      discountSearchContainer
    )
  }

  const discountsBeneficiaryContainer = document.getElementById('discounts-beneficiary-app');
  if (discountsBeneficiaryContainer) {
    ReactDOM.render (
      <Provider store={store}>
        <DiscountsBeneficiary goToUrl={discountsBeneficiaryContainer.dataset.goToUrl} />
      </Provider>,
      discountsBeneficiaryContainer
    )
  }

  loadInitialDiscounts(store)
  window.onpopstate = getDiscountQueryStringChangeHandler(store)

} else if (location.pathname === '/podaruj1zl/' || location.pathname === '/konto/potwierdzono-email/') {
  const give1zlContainer = document.getElementById('give-away-1zl')
  if (give1zlContainer) {
    ReactDOM.render (
      <Provider store={store}>
        <FmextGiveAway1zl />
      </Provider>,
      give1zlContainer
    )
  }
} else if (location.pathname.includes('przejdz-do-sklepu/zaloguj')) {
  const gotopartnerBeneficiaryContainer = document.getElementById('gotopartner-beneficiary-app')
  if(gotopartnerBeneficiaryContainer){
    ReactDOM.render (
      <Provider store={store}>
        <UserAccountBeneficiary 
          goToUrl={gotopartnerBeneficiaryContainer.dataset.action} 
          hideDismiss={true} 
          partner={gotopartnerBeneficiaryContainer.dataset.partner}
          label={gotopartnerBeneficiaryContainer.dataset.label}
          customClasses='btn btn-white btn-inline btn-link btn-span-as-link'
          loginModal='#partner-redirect-modal'
          />
      </Provider>,
      gotopartnerBeneficiaryContainer
    )
  }
}

if (location.pathname.indexOf('/mobileapp-beneficiary/') === 0) {
  const mobileAppBeneficiarySearchContainer = document.getElementById('mobileapp-beneficiary-search-app')
  ReactDOM.render (
    <Provider store={store}>
      <MobileAppBeneficiarySearch parentId="0" popularOrgs={FM.popularBeneficiaries}/>
    </Provider>,
    mobileAppBeneficiarySearchContainer
  )
  loadBeneficiaryInitialSearchResults(store, {parent:0})
  window.onpopstate = getBeneficiaryQueryStringChangeHandler(store)
}

if (location.pathname.indexOf('/spolecznosc/dodaj/') === 0) {
  const communitiesBeneficiaryContainer = document.getElementById('communities-beneficiary-app')
  ReactDOM.render (
    <Provider store={store}>
      <UserAccountBeneficiary 
        goToUrl={communitiesBeneficiaryContainer.dataset.goToUrl} 
        customClasses='btn btn-red btn-nouppercase'
      />
    </Provider>,
    communitiesBeneficiaryContainer
  )
}

// TODO: Remove 1zl cta widget code completely as it will no longer be in use
const browser = detectBrowser()
const fmextCtaWidgetNode = document.getElementById('fmext-cta-widget')
if (fmextCtaWidgetNode && (browser === 'chrome' || browser === 'firefox')) {
  ReactDOM.render (
    <Provider store={store}>
      <FmextCtaWidget />
    </Provider>,
    fmextCtaWidgetNode
  )
}

const fmext1zlButtonNode = document.getElementById('btn-1zl-wrap')
if (fmext1zlButtonNode) {
  ReactDOM.render (
    <Provider store={store}>
      <Fmext1zlCtaButton previewedBeneficiaryId={fmext1zlButtonNode.dataset.beneficiaryId} />
    </Provider>,
    fmext1zlButtonNode
  )
}